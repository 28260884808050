<template>

    <ReportTotals :activeProjectDefault='activeProjectDefault' :is-project-report='false' :report='report' />


    <div class='grid'>
        <div class='col-12 mt-4'>
            <div>
                <Toast />
                <Toolbar class='mb-2'>

                    <template v-slot:start>
                        <div>
                            <Calendar id='paymentDate' :showIcon='true' dateFormat='dd.mm.yy - DD' :showButtonBar='true' v-model='paymentDate' @date-select='paymentDateChanged'></Calendar>
                        </div>
                    </template>

                    <template v-slot:end>

                        <Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
                            <h3>Hakediş Ekleme Yardım</h3>
                            <p class='text-lg'>
                                Bu bölüm günlük olarak gün sonunda hesaplanarak doldurulmalıdır.<br>
                                <br>
                                <b>01.ADIM</b><br>
                                + Getir butonuna tıklayarak aktif olan taşeron firmaların siparişlerini getirin.<br>
                                <br>
                                <b>02.ADIM</b><br>
                                Her firmanın karşısında bulunan güncelle butonuna tıklayarak açılan sayfada gerçekleşen
                                satışları girin ve kaydet butonuna tıklayın.<br>
                                <br>
                                Firmaya o gün içinde hiçbir öğünde servis yapılmadı ise sadece kaydet butonuna tıklayın.
                                Firmanın sipariş verdiği rakamlara göre sistem hesaplama yapacaktır.<br>
                                <br>
                                <b>03.ADIM</b><br>
                                Verileri girdiğinizde Durum sütunundaki kırmızı X işareti tamamlandı anlamına gelen
                                yeşil renkteki okey işaretine dönecektir.<br>
                                <br>
                                Sayfanın yukarısında bulunan öğün kutucukları veri girdikçe girdiğiniz verilerin
                                toplamını verecektir.<br>
                                <br>
                                Öğün parasal değerleri proje detay sayfasında bulunmaktadır.<br>
                                <br>
                                Fiyat güncelleme butonu sadece geçmişe yönelik fiyat değişkenliklerinde kullanılmalıdır.
                            </p>
                        </Sidebar>

                        <Button label='Excele Aktar' icon='pi pi-file-excel' class='p-button-success mr-2' @click='exportExcel'></Button>

                        <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2' />


                        <a href='/#/HakedisEklemeVideo' target='_blank'>
                            <Button label='Video' icon='pi pi-play' class='p-button-help mr-2' />
                        </a>

                        <Button label='Getir' icon='pi pi-plus' class='p-button' @click='showCalculateDialog' />

                    </template>
                </Toolbar>


                <DataTable ref='dt' :value='foodPaymentList' dataKey='id' :paginator='true' :rows='100' :filters='filters' :loading='loadingIndicator' paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown' :rowsPerPageOptions='[5,10,25,50,100,200,300]' currentPageReportTemplate='{first} Sayfada {last} Kayıt Gösteriliyor. Toplam Kayıt: {totalRecords}' responsiveLayout='scroll' showGridlines stripedRows :globalFilterFields="['name','firm.name']"> 
                    <template #header>
                        <div class='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
                            <h5 class='mb-3 mt-3'>Hakediş Girişi</h5>
                            <span class='block mt-2 md:mt-0 p-input-icon-left'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Arama...' />
                            </span>
                        </div>
                    </template>

                    <template #loading>
                        Hakedişler Hesaplanıyor. Lütfen Bekleyin.
                    </template>

                    <template #empty>
                        Seçili Projeye Ait {{ formatDate(paymentDate) }} Günü Hakediş Bulunamadı.
                    </template>

                    <Column field='firm.name' header='Firma' :sortable='false'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Firma</span>
                            {{ slotProps.data.firm.name }}
                        </template>
                    </Column>

                    <Column field='breakfastExpectedValue' header='Sabah Sipariş' :sortable='false' style='width:5rem;' bodyClass='text-center' v-if='activeProjectDefault.isBreakfastVisible && activeProjectDefault.isBreakfastOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sabah Sipariş</span>
                            <span class='validQtyDefault'>{{ slotProps.data.breakfastExpectedValue }}</span>
                        </template>
                    </Column>

                    <Column field='breakfastActualValue' header='Sabah Servis' :sortable='false' style='width:6rem;' bodyClass='text-center' v-if='activeProjectDefault.isBreakfastVisible && activeProjectDefault.isBreakfastOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sabah Servis</span>
                            <span :class='actualQtyClass(slotProps.data.breakfastActualValue)'>
                                {{ slotProps.data.breakfastActualValue }}
                            </span>

                            <i class='ml-2' v-if='activeProjectDefault.isBreakfastOrderVisible && slotProps.data.breakfastActualValue < slotProps.data.breakfastExpectedValue'>
                                <img src='/layout/images/icon/down.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='activeProjectDefault.isBreakfastOrderVisible && slotProps.data.breakfastActualValue > slotProps.data.breakfastExpectedValue'>
                                <img src='/layout/images/icon/up.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='activeProjectDefault.isBreakfastOrderVisible && slotProps.data.breakfastActualValue == slotProps.data.breakfastExpectedValue'>
                                <img src='/layout/images/icon/minus.svg' height='9' />
                            </i>
                        </template>
                    </Column>

                    <Column field='breakfastCalculatedValue' header='Sabah Satış' :sortable='false' style='width:5rem;' bodyClass='text-center' v-if='activeProjectDefault.isBreakfastVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sabah Satış</span>
                            <span class='validQtyDefault'>{{ slotProps.data.breakfastCalculatedValue }}</span>
                        </template>
                    </Column>

                    <Column field='lunchExpectedValue' header='Öğlen Sipariş' :sortable='false' style='width:5rem;' bodyClass='text-center' v-if='activeProjectDefault.isLunchVisible && activeProjectDefault.isLunchOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Öğlen Sipariş</span>
                            <span class='validQtyDefault'>{{ slotProps.data.lunchExpectedValue }}</span>
                        </template>
                    </Column>

                    <Column field='lunchActualValue' header='Öğlen Servis' :sortable='false' style='width:6rem;' bodyClass='text-center' v-if='activeProjectDefault.isLunchVisible && activeProjectDefault.isLunchOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Öğlen Servis</span>
                            <span :class='actualQtyClass(slotProps.data.lunchActualValue)'>
                                {{ slotProps.data.lunchActualValue }}
                            </span>

                            <i class='ml-2' v-if='activeProjectDefault.isLunchOrderVisible && slotProps.data.lunchActualValue < slotProps.data.lunchExpectedValue'>
                                <img src='/layout/images/icon/down.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='activeProjectDefault.isLunchOrderVisible && slotProps.data.lunchActualValue > slotProps.data.lunchExpectedValue'>
                                <img src='/layout/images/icon/up.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='activeProjectDefault.isLunchOrderVisible && slotProps.data.lunchActualValue == slotProps.data.lunchExpectedValue'>
                                <img src='/layout/images/icon/minus.svg' height='9' />
                            </i>
                        </template>
                    </Column>

                    <Column field='lunchCalculatedValue' header='Öğlen Satış' :sortable='false' style='width:5rem;' bodyClass='text-center'  v-if='activeProjectDefault.isLunchVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Öğlen Satış</span>
                            <span class='validQtyDefault'>{{ slotProps.data.lunchCalculatedValue }}</span>
                        </template>
                    </Column>

                    <Column field='dinnerExpectedValue' header='Akşam Sipariş' :sortable='false' style='width:5rem;' bodyClass='text-center' v-if='activeProjectDefault.isDinnerVisible && activeProjectDefault.isDinnerOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Akşam Sipariş</span>
                            <span class='validQtyDefault'>{{ slotProps.data.dinnerExpectedValue }}</span>
                        </template>
                    </Column>

                    <Column field='dinnerActualValue' header='Akşam Servis' :sortable='false' style='width:6rem;' bodyClass='text-center' v-if='activeProjectDefault.isDinnerVisible && activeProjectDefault.isDinnerOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Akşam Servis</span>
                            <span :class='actualQtyClass(slotProps.data.dinnerActualValue)'>
                                {{ slotProps.data.dinnerActualValue }}
                            </span>

                            <i class='ml-2' v-if='activeProjectDefault.isDinnerOrderVisible && slotProps.data.dinnerActualValue < slotProps.data.dinnerExpectedValue'>
                                <img src='/layout/images/icon/down.svg' height='9' />
                            </i>

                            <i class='ml-2' v-if='activeProjectDefault.isDinnerOrderVisible && slotProps.data.dinnerActualValue > slotProps.data.dinnerExpectedValue'>
                                <img src='/layout/images/icon/up.svg' height='9' />
                            </i>

                            <i class='ml-2' v-if='activeProjectDefault.isDinnerOrderVisible && slotProps.data.dinnerActualValue == slotProps.data.dinnerExpectedValue'>
                                <img src='/layout/images/icon/minus.svg' height='9' />
                            </i>
                        </template>
                    </Column>

                    <Column field='dinnerCalculatedValue' header='Akşam Satış' :sortable='false' style='width:5rem;' bodyClass='text-center'  v-if='activeProjectDefault.isDinnerVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Akşam Satış</span>
                            <span class='validQtyDefault'>{{ slotProps.data.dinnerCalculatedValue }}</span>
                        </template>
                    </Column>

                    <Column field='nightMealExpectedValue' header='Gece Sipariş' :sortable='false' style='width:5rem;' bodyClass='text-center' v-if='activeProjectDefault.isNightMealVisible && activeProjectDefault.isNightMealOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Gece Sipariş</span>
                            <span class='validQtyDefault'>
                                {{ slotProps.data.nightMealExpectedValue }}
                            </span>
                        </template>
                    </Column>

                    <Column field='nightMealActualValue' header='Gece Servis' :sortable='false' style='width:6rem;' bodyClass='text-center' v-if='activeProjectDefault.isNightMealVisible && activeProjectDefault.isNightMealOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Gece Servis</span>
                            <span :class='actualQtyClass(slotProps.data.nightMealActualValue)'>
                                {{ slotProps.data.nightMealActualValue }}
                            </span>

                            <i class='ml-2' v-if='activeProjectDefault.isNightMealOrderVisible && slotProps.data.nightMealActualValue < slotProps.data.nightMealExpectedValue'>
                                <img src='/layout/images/icon/down.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='activeProjectDefault.isNightMealOrderVisible && slotProps.data.nightMealActualValue > slotProps.data.nightMealExpectedValue'>
                                <img src='/layout/images/icon/up.svg' height='9' />
                            </i>

                            <i class='ml-2' v-if='activeProjectDefault.isNightMealOrderVisible && slotProps.data.nightMealActualValue == slotProps.data.nightMealExpectedValue'>
                                <img src='/layout/images/icon/minus.svg' height='9' />
                            </i>
                        </template>
                    </Column>

                    <Column field='nightMealCalculatedValue' header='Gece Satış' :sortable='false' style='width:5rem;' bodyClass='text-center' v-if='activeProjectDefault.isNightMealVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Gece Satış</span>
                            <span class='validQtyDefault'>{{ slotProps.data.nightMealCalculatedValue }}</span>
                        </template>
                    </Column>

                    <Column field='snackExpectedValue' header='Kumanya Sipariş' :sortable='false' style='width:5rem;' bodyClass='text-center' v-if='activeProjectDefault.isSnackVisible && activeProjectDefault.isSnackOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Kumanya Sipariş</span>
                            <span class='validQtyDefault'>{{ slotProps.data.snackExpectedValue }}</span>
                        </template>
                    </Column>

                    <Column field='snackActualValue' header='Kumanya Servis' :sortable='false' style='width:6rem;' bodyClass='text-center' v-if='activeProjectDefault.isSnackVisible && activeProjectDefault.isSnackOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Kumanya Servis</span>
                            <span :class='actualQtyClass(slotProps.data.snackActualValue)'>
                                {{ slotProps.data.snackActualValue }}
                            </span>

                            <i class='ml-2' v-if='activeProjectDefault.isSnackOrderVisible && slotProps.data.snackActualValue < slotProps.data.snackExpectedValue'>
                                <img src='/layout/images/icon/down.svg' height='9' />
                            </i>

                            <i class='ml-2' v-if='activeProjectDefault.isSnackOrderVisible && slotProps.data.snackActualValue > slotProps.data.snackExpectedValue'>
                                <img src='/layout/images/icon/up.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='activeProjectDefault.isSnackOrderVisible && slotProps.data.snackActualValue == slotProps.data.snackExpectedValue'>
                                <img src='/layout/images/icon/minus.svg' height='9' />
                            </i>
                        </template>
                    </Column>

                    <Column field='snackCalculatedValue' header='Kumanya Satış' :sortable='false' style='width:5rem;' bodyClass='text-center'  v-if='activeProjectDefault.isSnackVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Kumanya Satış</span>
                            <span class='validQtyDefault'>{{ slotProps.data.snackCalculatedValue }}</span>
                        </template>
                    </Column>

                    <Column field='iftarExpectedValue' header='İftar Sipariş' :sortable='false' style='width:5rem;' bodyClass='text-center' v-if='activeProjectDefault.isIftarVisible && activeProjectDefault.isIftarOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>İftar Sipariş</span>
                            <span class='validQtyDefault'>{{ slotProps.data.iftarExpectedValue }}</span>
                        </template>
                    </Column>

                    <Column field='iftarActualValue' header='İftar Servis' :sortable='false' style='width:6rem;' bodyClass='text-center' v-if='activeProjectDefault.isIftarVisible && activeProjectDefault.isIftarOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>İftar Servis</span>
                            <span :class='actualQtyClass(slotProps.data.iftarActualValue)'>
                                {{ slotProps.data.iftarActualValue }}
                            </span>

                            <i class='ml-2' v-if='activeProjectDefault.isIftarOrderVisible && slotProps.data.iftarActualValue < slotProps.data.iftarExpectedValue'>
                                <img src='/layout/images/icon/down.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='activeProjectDefault.isIftarOrderVisible && slotProps.data.iftarActualValue > slotProps.data.iftarExpectedValue'>
                                <img src='/layout/images/icon/up.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='activeProjectDefault.isIftarOrderVisible && slotProps.data.iftarActualValue == slotProps.data.iftarExpectedValue'>
                                <img src='/layout/images/icon/minus.svg' height='9' />
                            </i>
                        </template>
                    </Column>

                    <Column field='iftarCalculatedValue' header='İftar Satış' :sortable='false' style='width:5rem;' bodyClass='text-center' v-if='activeProjectDefault.isIftarVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>İftar Satış</span>
                            <span class='validQtyDefault'>{{ slotProps.data.iftarCalculatedValue }}</span>
                        </template>
                    </Column>

                    <Column field='sahurExpectedValue' header='Sahur Sipariş' :sortable='false' style='width:5rem;' bodyClass='text-center' v-if='activeProjectDefault.isSahurVisible && activeProjectDefault.isSahurOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sahur Sipariş</span>
                            <span class='validQtyDefault'>{{ slotProps.data.sahurExpectedValue }}</span>
                        </template>
                    </Column>

                    <Column field='sahurActualValue' header='Sahur Servis' :sortable='false' style='width:6rem;' bodyClass='text-center' v-if='activeProjectDefault.isSahurVisible && activeProjectDefault.isSahurOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sahur Servis</span>
                            <span :class='actualQtyClass(slotProps.data.sahurActualValue)'>
                                {{ slotProps.data.sahurActualValue }}
                            </span>

                            <i class='ml-2' v-if='activeProjectDefault.isSahurOrderVisible && slotProps.data.sahurActualValue < slotProps.data.sahurExpectedValue'>
                                <img src='/layout/images/icon/down.svg' height='9' />
                            </i>
                            
                            <i class='ml-2' v-if='activeProjectDefault.isSahurOrderVisible && slotProps.data.sahurActualValue > slotProps.data.sahurExpectedValue'>
                                <img src='/layout/images/icon/up.svg' height='9' />
                            </i>

                            <i class='ml-2' v-if='activeProjectDefault.isSahurOrderVisible && slotProps.data.sahurActualValue == slotProps.data.sahurExpectedValue'>
                                <img src='/layout/images/icon/minus.svg' height='9' />
                            </i>
                        </template>
                    </Column>

                    <Column field='sahurCalculatedValue' header='Sahur Satış' :sortable='false' style='width:5rem;' bodyClass='text-center'  v-if='activeProjectDefault.isSahurVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sahur Satış</span>
                            <span class='validQtyDefault'>{{ slotProps.data.sahurCalculatedValue }}</span>
                        </template>
                    </Column>

                    <Column field='verified' header='Durum' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isProcessed, 'text-pink-500 pi-times-circle': !data.isProcessed}"></i>
                        </template>
                        <template #filter={filterModel}>
                            <TriStateCheckbox v-model='filterModel.value' />
                        </template>
                    </Column>

                    <Column style='width:4rem;' header='Güncelle' bodyClass='text-center'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-pencil' class='p-button-rounded p-button-primary' @click='editFoodPayment(slotProps.data)' />
                        </template>
                    </Column>

                </DataTable>

                <Dialog v-model:visible='updateActualValueDialog' :style="{width: '540px'}" header='Firma Gerçekleşen Adetler' :modal='true' class='p-fluid'>

                    <div class='surface-section'>
                        <div class='font-medium text-3xl mb-0'>{{ foodPayment.firm.name }}</div>
                        <div class='text-500 mb-3'>
                            Miktarı (0) sıfır olarak kayıt ettiğinizde. Sipariş rakamına göre hesaplama yapılır.
                        </div>

                        <ul class='list-none p-0 m-0'>

                            <li class='flex align-items-center py-2 px-1 border-top-1 surface-border flex-wrap'>
                                <div class='text-500 w-6 md:w-6 font-bold p-1'>Öğün</div>
                                <div class='text-500 w-2 md:w-2 font-bold p-1'>Sipariş</div>
                                <div class='text-500 w-2 md:w-2 font-bold p-1'>Fiyat</div>
                                <div class='text-500 w-2 md:w-2 font-bold p-1'>Servis</div>
                            </li>

                            <li class='flex align-items-center py-2 px-1 border-top-1 surface-border flex-wrap' v-if='this.activeProjectDefault.isBreakfastVisible'>
                                <div class='text-500 w-6 md:w-6 font-medium p-1'>Sabah Kahvaltısı</div>
                                
                                <div class='w-2 md:w-2 p-1'>
                                    <div v-if='this.activeProjectDefault.isBreakfastOrderVisible'>
                                        <InputNumber id='breakfastExpectedValue' v-model='foodPayment.breakfastExpectedValue' integeronly />
                                    </div>
                                </div>

                                <div class='w-2 md:w-2 p-1'>
                                    <div class='align-items-center justify-content-center bg-green-700 border-round text-white p-2'>
                                        {{ foodPayment.breakfastPrice }} <span>&#8378;</span>
                                    </div>
                                </div>                       

                                <div class='w-2 md:w-2 p-1'>
                                    <InputNumber id='breakfastActualValue' v-model='foodPayment.breakfastActualValue' integeronly />
                                </div>
                            </li>

                            <li class='flex align-items-center py-2 px-1 border-top-1 surface-border flex-wrap' v-if='this.activeProjectDefault.isLunchVisible'>
                                <div class='text-500 w-6 md:w-6 font-medium p-1'>Öğlen Yemeği</div>
                                
                                <div class='w-2 md:w-2 p-1'>
                                    <div v-if='this.activeProjectDefault.isLunchOrderVisible'>
                                        <InputNumber id='lunchExpectedValue' v-model='foodPayment.lunchExpectedValue' integeronly /> 
                                    </div>
                                </div>

                                <div class='w-2 md:w-2 p-1'>
                                    <div class='align-items-center justify-content-center bg-green-700 border-round text-white p-2'>
                                        {{ foodPayment.lunchPrice }} <span>&#8378;</span>
                                    </div>
                                </div>                    

                                <div class='w-2 md:w-2 p-1'>
                                    <InputNumber id='lunchActualValue' v-model='foodPayment.lunchActualValue' integeronly />
                                </div>
                            </li>

                            <li class='flex align-items-center py-2 px-1 border-top-1 surface-border flex-wrap' v-if='this.activeProjectDefault.isDinnerVisible'>
                                <div class='text-500 w-6 md:w-6 font-medium p-1'>Akşam Yemeği</div>

                                <div class='w-2 md:w-2 p-1'>
                                    <div v-if='this.activeProjectDefault.isDinnerOrderVisible'>
                                        <InputNumber id='dinnerExpectedValue' v-model='foodPayment.dinnerExpectedValue' integeronly />
                                    </div>
                                </div>

                                <div class='w-2 md:w-2 p-1'>
                                    <div class='align-items-center justify-content-center bg-green-700 border-round text-white p-2'>
                                        {{ foodPayment.dinnerPrice }} <span>&#8378;</span>
                                    </div>
                                </div>                     
                                
                                <div class='w-2 md:w-2 p-1'>
                                    <InputNumber id='dinnerActualValue' v-model='foodPayment.dinnerActualValue' integeronly />
                                </div>
                            </li>

                            <li class='flex align-items-center py-2 px-1 border-top-1 surface-border flex-wrap' v-if='this.activeProjectDefault.isNightMealVisible'>
                                <div class='text-500 w-6 md:w-6 font-medium p-1'>Gece Yemeği</div>
                                
                                <div class='w-2 md:w-2 p-1'>
                                    <div v-if='this.activeProjectDefault.isNightMealOrderVisible'>
                                        <InputNumber id='nightMealExpectedValue' v-model='foodPayment.nightMealExpectedValue' integeronly />
                                    </div>
                                </div>

                                <div class='w-2 md:w-2 p-1'>
                                    <div class='align-items-center justify-content-center bg-green-700 border-round text-white p-2'>
                                        {{ foodPayment.nightMealPrice }} <span>&#8378;</span>
                                    </div>
                                </div>          

                                <div class='w-2 md:w-2 p-1'>
                                    <InputNumber id='nightMealActualValue' v-model='foodPayment.nightMealActualValue' integeronly />
                                </div>
                            </li>

                            <li class='flex align-items-center py-2 px-1 border-top-1 surface-border flex-wrap' v-if='this.activeProjectDefault.isSnackVisible'>
                                <div class='text-500 w-6 md:w-6 font-medium p-1'>Kumanya</div>

                                <div class='w-2 md:w-2 p-1'>
                                    <div v-if='this.activeProjectDefault.isSnackOrderVisible'>
                                        <InputNumber id='snackExpectedValue' v-model='foodPayment.snackExpectedValue' integeronly />
                                    </div>
                                </div>

                                <div class='w-2 md:w-2 p-1'>
                                    <div class='align-items-center justify-content-center bg-green-700 border-round text-white p-2'>
                                        {{ foodPayment.snackPrice }} <span>&#8378;</span>
                                    </div>
                                </div>

                                <div class='w-2 md:w-2 p-1'>
                                    <InputNumber id='snackActualValue' v-model='foodPayment.snackActualValue' integeronly />
                                </div>
                            </li>


                            <li class='flex align-items-center py-2 px-1 border-top-1 surface-border flex-wrap' v-if='this.activeProjectDefault.isIftarVisible'>
                                <div class='text-500 w-6 md:w-6 font-medium p-1'>İftar</div>

                                <div class='w-2 md:w-2 p-1'>
                                    <div v-if='this.activeProjectDefault.isIftarOrderVisible'>
                                        <InputNumber id='iftarExpectedValue' v-model='foodPayment.iftarExpectedValue' integeronly />
                                    </div>
                                </div>

                                <div class='w-2 md:w-2 p-1'>
                                    <div class='align-items-center justify-content-center bg-green-700 border-round text-white p-2'>
                                        {{ foodPayment.iftarPrice }} <span>&#8378;</span>
                                    </div>
                                </div>                 

                                <div class='w-2 md:w-2 p-1'>
                                    <InputNumber id='iftarActualValue' v-model='foodPayment.iftarActualValue' integeronly />
                                </div>
                            </li>

                            <li class='flex align-items-center py-2 px-1 border-top-1 surface-border flex-wrap' v-if='this.activeProjectDefault.isSahurVisible'>
                                <div class='text-500 w-6 md:w-6 font-medium p-1'>Sahur</div>

                                <div class='w-2 md:w-2 p-1'>
                                    <div v-if='this.activeProjectDefault.isSahurOrderVisible'>
                                        <InputNumber id='sahurExpectedValue' v-model='foodPayment.sahurExpectedValue' integeronly />
                                    </div>
                                </div>

                                <div class='w-2 md:w-2 p-1'>
                                    <div class='align-items-center justify-content-center bg-green-700 border-round text-white p-2'>
                                        {{ foodPayment.sahurPrice }} <span>&#8378;</span>
                                    </div>
                                </div>

                                <div class='w-2 md:w-2 p-1'>
                                    <InputNumber id='sahurActualValue' v-model='foodPayment.sahurActualValue' integeronly />
                                </div>
                            </li>
                        </ul>

                    </div>

                    <template #footer>
                        <Button label='Vazgeç' icon='pi pi-times' class='p-button-text' @click='resetActualValues' />
                        <Button label='Sil' icon='pi pi-check' class='p-button-text' @click='deleteActualValuesDialog = true' />
                        <Button label='Kaydet' icon='pi pi-check' class='p-button-text' @click='checkActualValues' />
                    </template>

                </Dialog>

                <Dialog v-model:visible='priceUpdateDialog' :style="{width: '450px'}" header='Toplu Fiyat Güncelleme' :modal='true' class='p-fluid'>
                    <div class='field'>
                        {{ formatDate(priceUpdateData.paymentDate) }} gününe ait girmiş olduğunuz satışlar aşağıda yer
                        alan fiyatlandırmaya göre güncellenecektir. Bu güncelleme geri alınamaz.
                    </div>

                    <div class='field' v-if='this.activeProjectDefault.isBreakfastVisible'>
                        <label for='breakfastActualValue'><b>Kahvaltı Ücreti:</b> {{ priceUpdateData.breakfastPrice }} <span>&#8378;</span></label>
                    </div>

                    <div class='field' v-if='this.activeProjectDefault.isLunchVisible'>
                        <label for='lunchActualValue'><b>Öğlen Yemeği Ücreti:</b> {{ priceUpdateData.lunchPrice }} <span>&#8378;</span></label>
                    </div>

                    <div class='field' v-if='this.activeProjectDefault.isDinnerVisible'>
                        <label for='dinnerActualValue'><b>Akşam Yemeği Ücreti:</b> {{ priceUpdateData.dinnerPrice }} <span>&#8378;</span></label>
                    </div>

                    <div class='field' v-if='this.activeProjectDefault.isNightMealVisible'>
                        <label for='nightMealActualValue'><b>Gece Yemeği Ücreti:</b>
                            {{ priceUpdateData.nightMealPrice }} <span>&#8378;</span></label>
                    </div>

                    <div class='field' v-if='this.activeProjectDefault.isSnackVisible'>
                        <label for='snackActualValue'><b>Kumanya Ücreti:</b> {{ priceUpdateData.snackPrice }} <span>&#8378;</span></label>
                    </div>

                    <div class='field' v-if='this.activeProjectDefault.isIftarVisible'>
                        <label for='iftarActualValue'><b>İftar Ücreti:</b> {{ priceUpdateData.iftarPrice }} <span>&#8378;</span></label>
                    </div>

                    <div class='field' v-if='this.activeProjectDefault.isSahurVisible'>
                        <label for='sahurActualValue'><b>Sahur Ücreti:</b> {{ priceUpdateData.sahurPrice }} <span>&#8378;</span></label>
                    </div>

                    <template #footer>
                        <Button label='Güncelle' icon='pi pi-check' class='p-button-text' @click='updateFoodPaymentPrices' />
                    </template>
                </Dialog>

                <Dialog v-model:visible='zeroCalculateDialog' :style="{width: '450px'}" header='Hakediş Hesapla' :modal='true'>
                    <div class='flex align-items-center justify-content-center'>
                        <i class='pi pi-exclamation-triangle mr-3' style='font-size: 2rem' />
                        <span> Öğünlerin tamamı değer olarak 0 (sıfır) gözükmektedir. Taahhüt edilen rakamlara göre hesaplama yapılacaktır. Firma sistemden çıkış yaptı ise silip firmayı pasif duruma getiriniz.</span>
                    </div>
                    <template #footer>
                        <Button label='Vazgeç' icon='pi pi-times' class='p-button-text' @click='zeroCalculateDialog = false' />
                        <Button label='Evet' icon='pi pi-check' class='p-button-text' @click='saveActualValues' />
                    </template>
                </Dialog>

                <Dialog v-model:visible='calculateDialog' :style="{width: '450px'}" header='Hakediş Hesapla' :modal='true'>
                    <div class='flex align-items-center justify-content-center'>
                        <i class='pi pi-exclamation-triangle mr-3' style='font-size: 2rem' />
                        <span> <b>{{ formatDate(this.paymentDate) }}</b> gününe ait hakedişlerin hesaplanmasını istiyormusunuz?</span>
                    </div>
                    <template #footer>
                        <Button label='Vazgeç' icon='pi pi-times' class='p-button-text'  @click='hideCalculateDialog' />
                        <Button label='Evet' icon='pi pi-check' class='p-button-text' v-bind:disabled='isProcessing' @click='calculatePayments' />
                    </template>
                </Dialog>

                <Dialog v-model:visible='deleteActualValuesDialog' :style="{width: '450px'}" header='UYARI' :modal='true'>
                    <div class='flex align-items-center justify-content-center'>
                        <i class='pi pi-exclamation-triangle mr-3' style='font-size: 2rem' />
                        <span> {{ foodPayment.firm.name }} firmasına ait {{ formatDate(paymentDate) }} gününe bilgileri silmek istediğinize emin misiniz? Silme işlemi geri alınamaz.</span>
                    </div>
                    <template #footer>
                        <Button label='Vazgeç' icon='pi pi-times' class='p-button-text' @click='deleteActualValuesDialog = false' />
                        <Button label='Evet' icon='pi pi-check' class='p-button-text' @click='deleteActualValues' />
                    </template>

                </Dialog>

                <Toolbar class='mb-2 mt-4'>
                    <template v-slot:start>
                        <div>
                            <h5 class='mb-0'>{{ formatDate(paymentDate) }} Günü Toplam Ciro <span class='text-green-600'>{{ formatPrice(report.paymentTotal) }} &#8378;</span></h5>
                        </div>
                    </template>

                    <template v-slot:end>
                        <Button label='Fiyat Güncelle' icon='pi pi-spinner' class='p-button-warning' @click='showUpdatePrice' />
                    </template>
                </Toolbar>

            </div>
        </div>
    </div>

</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import FoodPaymentService from '../../services/foodPaymentService';
import moment from 'moment';
import { showErrorMessage, showSuccessMessage } from '../../components/shared/toast/toastFunction';
import ProjectDefaultService from '../../services/projectDefaultService';
import ReportTotals from '../../components/shared/ReportTotals';
import { getActiveProjectDefaults, getActiveProjectId, getUserId } from '../common/commonConstantFunctions';

export default {
    components: { ReportTotals },
    data() {
        return {
            isProcessing: false,
            activeProjectDefault: {
                breakfastPrice: '',
                lunchPrice: '',
                dinnerPrice: '',
                snackPrice: '',
                iftarPrice: '',
                sahurPrice: '',
                nightMealPrice: '',
                isBreakfastVisible: '',
                isLunchVisible: '',
                isDinnerVisible: '',
                isSnackVisible: '',
                isIftarVisible: '',
                isSahurVisible: '',
                isNightMealVisible: '',
                isBreakfastOrderVisible: '',
                isLunchOrderVisible: '',
                isDinnerOrderVisible: '',
                isIftarOrderVisible: '',
                isSnackOrderVisible: '',
                isSahurOrderVisible: '',
                isNightMealOrderVisible: '',
            },
            zeroCalculateDialog: false,
            loadingIndicator: false,
            paymentDate: null,
            paymentDateAsTimeStamp: 0,
            projectId: 0,
            userId: 0,
            priceUpdateDialog: false,
            calculateDialog: false,
            deleteActualValuesDialog: false,
            priceUpdateData: {
                breakfastPrice: '',
                lunchPrice: '',
                dinnerPrice: '',
                snackPrice: '',
                iftarPrice: '',
                sahurPrice: '',
                nightMealPrice: '',
            },
            report: {},
            foodPayment: {
                id: '',
                projectId: '',
                firmId: '',
                paymentDate: '',
                insertDate: '',
                mangerUserId: '',
                breakfastExpectedValue: '',
                breakfastActualValue: '',
                lunchExpectedValue: '',
                lunchActualValue: '',
                dinnerExpectedValue: '',
                dinnerActualValue: '',
                snackExpectedValue: '',
                snackActualValue: '',
                iftarExpectedValue: '',
                iftarActualValue: '',
                sahurExpectedValue: '',
                sahurActualValue: '',
                breakfastPrice: '',
                lunchPrice: '',
                dinnerPrice: '',
                snackPrice: '',
                iftarPrice: '',
                sahurPrice: '',
                nightMealPrice: '',
                nightMealActualValue: '',
                nightMealExpectedValue: '',
            },
            foodPaymentList: null,
            updateActualValueDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            submitted: false,
            dataItem: {
                projectId: 0,
                paymentDate: '',
                managerUserId: 0,
            },

            display: false,
            visibleLeft: false,
            filters: null,

        };
    },
    foodPaymentService: null,
    projectDefaultService: null,
    created() {
        this.loadingIndicator = true;
        this.paymentDateParameter = this.$route.params.paymentDate;
        if (this.paymentDateParameter == null) {
            this.paymentDate = moment().toDate();
        } else {
            this.paymentDate = moment.unix(this.paymentDateParameter / 1000).toDate();
        }

        this.paymentDateAsTimeStamp = moment(this.paymentDate).valueOf();
        this.projectId = getActiveProjectId();
        this.activeProjectDefault = getActiveProjectDefaults();
        this.userId = getUserId();
        this.foodPaymentService = new FoodPaymentService();
        this.projectDefaultService = new ProjectDefaultService();
        this.initFilters();
    },

    async mounted() {
        await this.refreshFoodPaymentLists();

    },
    methods: {
        async getFoodPaymentReport() {
            let getReportResponse = await this.foodPaymentService.getProjectFoodReports(this.projectId, this.paymentDateAsTimeStamp);
            if (getReportResponse.isSuccess) {
                this.report = getReportResponse.data;
                this.loadingIndicator = false;
            }
        },
        showCalculateDialog() {
            this.calculateDialog = true;
        },
        hideCalculateDialog() {
            this.calculateDialog = false;
        },
        async exportExcel() {
            this.loadingIndicator = true;
            let excelExports = await this.foodPaymentService.getProjectFoodExcel(this.projectId, this.paymentDateAsTimeStamp);
            let blob = new Blob([excelExports.data], { type: excelExports.headers['content-type'] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Hakdeşiler ' + moment(this.paymentDate).format('DD.MM.YYYY') + '.xlsx';
            link.click();
            this.loadingIndicator = false;
        },
        async showUpdatePrice() {
            this.loadingIndicator = true;
            let projectDefaults = await this.projectDefaultService.getProjectDefaultsById(this.projectId);
            if (projectDefaults.isSuccess) {
                this.priceUpdateData.breakfastPrice = projectDefaults.data.breakfastPrice;
                this.priceUpdateData.lunchPrice = projectDefaults.data.lunchPrice;
                this.priceUpdateData.dinnerPrice = projectDefaults.data.dinnerPrice;
                this.priceUpdateData.snackPrice = projectDefaults.data.snackPrice;
                this.priceUpdateData.iftarPrice = projectDefaults.data.iftarPrice;
                this.priceUpdateData.sahurPrice = projectDefaults.data.sahurPrice;
                this.priceUpdateData.nightMealPrice = projectDefaults.data.nightMealPrice;
                this.priceUpdateData.paymentDate = this.paymentDate;
                this.priceUpdateDialog = true;
            }
            this.loadingIndicator = false;
        },
        async paymentDateChanged() {
            this.loadingIndicator = true;
            this.paymentDateAsTimeStamp = moment(this.paymentDate).valueOf();
            await this.refreshFoodPaymentLists();
        },

        async calculatePayments() {
            this.isProcessing = true;
            this.loadingIndicator = true;
            this.dataItem.managerUserId = this.userId;
            this.dataItem.paymentDate = this.paymentDateAsTimeStamp;
            this.dataItem.projectId = this.projectId;
            let calculateResponse = await this.foodPaymentService.calculateFoodPayment(this.dataItem);
            if (calculateResponse.isSuccess) {
                await this.refreshFoodPaymentLists();
                showSuccessMessage(this, 'Hesaplama İşlemi Başarılı');
            } else {
                showErrorMessage(this, 'Hesaplama İşleminde Hata Oluştu');
            }
            this.hideCalculateDialog();
            this.loadingIndicator = false;
            this.isProcessing = false;
        },
        async deleteActualValues() {
            this.loadingIndicator = true;
            let deleteItem = {
                projectId: this.foodPayment.projectId,
                firmId: this.foodPayment.firmId,
                managerUserId: this.foodPayment.mangerUserId,
                paymentDate: this.paymentDateAsTimeStamp,
            };
            let updateResponse = await this.foodPaymentService.deleteFoodPayment(deleteItem);
            if (updateResponse.isSuccess) {
                showSuccessMessage(this, 'Adet Güncelleme İşlemi Başarılı');
                await this.refreshFoodPaymentLists();
            } else {
                showErrorMessage(this, 'Adet Güncelleme İşleminde Hata Oluştu');
            }
            this.loadingIndicator = false;
            this.deleteActualValuesDialog = false;
            this.updateActualValueDialog = false;
        },
        async updateFoodPaymentPrices() {
            this.loadingIndicator = true;
            this.dataItem.paymentDate = this.paymentDateAsTimeStamp;
            this.dataItem.managerUserId = this.userId;
            this.dataItem.projectId = this.projectId;
            let updateResponse = await this.foodPaymentService.updateFoodPaymentPrices(this.dataItem);
            if (updateResponse.isSuccess) {
                showSuccessMessage(this, 'Fiyat Güncelleme İşlemi Başarılı');
                await this.refreshFoodPaymentLists();
            } else {
                showErrorMessage(this, updateResponse.message);
            }
            this.loadingIndicator = false;
            this.hideUpdatePriceDialog();
        },

        resetActualValues() {
            this.hideDialog();
            this.foodPayment.breakfastActualValue = 0;
            this.foodPayment.lunchActualValue = 0;
            this.foodPayment.dinnerActualValue = 0;
            this.foodPayment.snackActualValue = 0;
            this.foodPayment.iftarActualValue = 0;
            this.foodPayment.sahurActualValue = 0;
            this.foodPayment.nightMealActualValue = 0;
        },

        async checkActualValues() {

            if ((this.foodPayment.breakfastActualValue == 0 && this.activeProjectDefault.isBreakfastOrderVisible == true)
                && (this.foodPayment.lunchActualValue == 0 && this.activeProjectDefault.isLunchOrderVisible == true)
                && (this.foodPayment.dinnerActualValue == 0 && this.activeProjectDefault.isDinnerOrderVisible == true)
                && (this.foodPayment.snackActualValue == 0 && this.activeProjectDefault.isSnackOrderVisible == true)
                && (this.foodPayment.iftarActualValue == 0 && this.activeProjectDefault.isIftarOrderVisible == true)
                && (this.foodPayment.sahurActualValue == 0 && this.activeProjectDefault.isSahurOrderVisible == true)
                && (this.foodPayment.nightMealActualValue == 0 && this.activeProjectDefault.isNightMealOrderVisible == true)
            ) {
                this.zeroCalculateDialog = true;
                return;
            }


            await this.saveActualValues();
        },

        async saveActualValues() {
            this.loadingIndicator = true;
            this.foodPayment.paymentDate = this.paymentDateAsTimeStamp;
            let updateResponse = await this.foodPaymentService.updateFoodPayment(this.foodPayment);
            if (updateResponse.isSuccess) {
                showSuccessMessage(this, 'Adet Güncelleme İşlemi Başarılı');
                await this.refreshFoodPaymentLists();
            } else {
                showErrorMessage(this, 'Adet Güncelleme İşleminde Hata Oluştu');
            }
            this.loadingIndicator = false;
            this.hideDialog();
            this.zeroCalculateDialog = false;
        },

        async refreshFoodPaymentLists() {
            let getResponse = await this.foodPaymentService.getProjectFoodPayments(this.projectId, this.paymentDateAsTimeStamp);
            if (getResponse.isSuccess) {
                this.foodPaymentList = getResponse.data;
                this.loadingIndicator = false;
            }
            await this.getFoodPaymentReport();
        },
        hideUpdatePriceDialog() {
            this.priceUpdateDialog = false;
            this.submitted = false;
        },
        hideDialog() {
            this.updateActualValueDialog = false;
            this.submitted = false;
        },
        editFoodPayment(foodPayment) {
            this.foodPayment = foodPayment;
            this.updateActualValueDialog = true;
        },

        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        formatDate(value) {
            moment.locale('tr');
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY - dddd');
        },
        actualQtyClass(data) {
            return [
                {
                    'zeroQty': data === 0,
                    'validQty': data > 0,
                },
            ];
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },


    },
};
</script>

<style scoped lang='scss'>


.zeroQty {
    color: #FF5252;
    font-weight: bold;
    font-size: 16px;
}

.validQty {
    color: #495057;
    font-weight: bold;
    font-size: 16px;
}

.paymentTotal {
    font-weight: bold;
    font-size: 16px;
}

.validQtyDefault {
    font-weight: bold;
    font-size: 15px;
}


</style>



